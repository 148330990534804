<template>
  <div class="data-moniter-channel">
    <Header />
    <div class="header">
      <div class="tab" :class="{'active': tabIndex === 0}" @click="tabIndex = 0">终端</div>
      <div class="tab" :class="{'active': tabIndex === 1}" @click="tabIndex = 1">种植大户</div>
      <div class="tab" :class="{'active': tabIndex === 2}" @click="tabIndex = 2">会议</div>
      <div class="tab" :class="{'active': tabIndex === 3}" @click="tabIndex = 3">潜在客户</div>
    </div>
    <div id="myChart" class="channel-chart"></div>
  </div>
</template>

<script>
const echarts = require('echarts')
require('echarts/lib/chart/bar')
require('echarts/lib/chart/line')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/toolbox')
import Header from '../../../components/header';
export default {
  components: {
    Header,
  },
  data() { 
    return {
      tabIndex: 0,
    }
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine(){
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
          color: ['#F56C6C', '#E5A23C'],
          title: { 
            subtext: '销售年度',
            subtextStyle: {
              fontSize: '13px',
              fontWeight: 'bold',
              color: '#000'
            },
            x: 'center',
            left: '3%',
            top: '2%',
          },
          toolbox: {
            feature: {
              saveAsImage: {
                pixelRatio: 2
              },
              restore: {},
              magicType: {
                type: ['line', 'bar']
              },
              dataView: {},
            },
            right: '8%',
            top: '5%',
          },
          tooltip: {},
          xAxis: {
            data: ["11","12","01", "02", "03", "04", "05"]
          },
          yAxis: {},
          series: [{
            name: '销量',
            type: 'line',
            data: [2, 6, 12, 22, 26, 32, 28],
          }]
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.data-moniter-channel {
  min-height: 100vh;
  background-color: #FAFBFC;
  .header {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
    background-color: #fff;
    .tab {
      width: 81px;
      height: 29px;
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      border: 1px solid transparent;
      text-align: center;
      color: #818181;
      &.active {
        color: #CD001F;
        border: 1px solid #CD001F;
      }
    }
  }
  .channel-chart {
    width: 100%;
    height: 300px;
    margin-top: 6px;
    background-color: #fff;
  }
}
</style>